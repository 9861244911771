import { h } from "preact"

const Bubble = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 63.92 54.72"
    fill="currentColor"
  >
    <g>
      <path d="M34.61,27.22c0-4-.06-8,0-11.94C34.76,6.7,41.36-.11,49.34,0s14.39,6.78,14.5,15.2q.16,12.26,0,24.51c-.12,8.45-6.58,15-14.57,15s-14.46-6.5-14.64-14.93C34.53,35.6,34.61,31.41,34.61,27.22ZM57.9,21.1c-5.79,6.09-11.18,11.64-16.39,17.38a4.71,4.71,0,0,0-.66,3.64c1,4.13,5.15,7.14,9,6.8s7.8-3.83,8-8C58.13,34.54,57.9,28.16,57.9,21.1ZM40.6,33.56C46.38,27.48,51.77,21.91,57,16.18a4.41,4.41,0,0,0,.65-3.37A9.13,9.13,0,0,0,49,6c-3.94.11-8,3.42-8.29,7.62C40.32,20,40.6,26.5,40.6,33.56Z" />
      <path d="M8.15,49H29.63v5.74H.27c-.64-3.31-.23-6,2.16-8.68,5.94-6.69,11.65-13.63,17.22-20.67a21.72,21.72,0,0,0,4-7.7,9.43,9.43,0,0,0-5.31-11A8.38,8.38,0,0,0,8,9.71a9.83,9.83,0,0,0,.13,12.07c.43.57.9,1.1,1.48,1.82L6,28A15.85,15.85,0,0,1,1.31,9.36,14.77,14.77,0,0,1,17.72.27,15.3,15.3,0,0,1,29.84,14.63,18.55,18.55,0,0,1,25.4,27.82C20.25,34.12,15,40.37,9.86,46.65,9.36,47.26,8.92,47.93,8.15,49Z" />
    </g>
  </svg>
)

const Card = (props) => {
  return (
    <a
      href={props.url}
      class={[
        "card",
        `highlight-${props.byline?.color || "none"}`,
        props.isFeatured ? "is-featured" : "",
        props.isInverted ? "is-inverted" : "",
      ].join(" ")}
    >
      <div class="card-content">
        <div class="card-image">
          <div
            class="card-img"
            style={{ backgroundImage: `url("${props.image}")` }}
            aria-label={props.imageDescription}
          />
        </div>
        <div class="card-body">
          {props.isInverted ? null : (
            <div class={`card-bubble color-${props.byline?.color || "none"}`}>
              <Bubble />
            </div>
          )}
          <h2 class="card-title">{props.title}</h2>
        </div>
      </div>
    </a>
  )
}

export const Cards = ({ projects }) => (
  <div class="cards">
    {projects.map((project) => (
      <Card key={project.id} {...project} />
    ))}
  </div>
)
