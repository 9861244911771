import "./main.scss"

// import "blissfuljs/bliss.shy.js"
// import "blissfuljs/bliss._.js"
// const { $, selectArray } = window // eslint-disable-line

import scrollIntoView from "scroll-into-view-if-needed"

import { onReady, select, selectArray } from "./utils.js"

import { renderLocations } from "./locations.js"
import { renderProjects, renderProjectsTeaser } from "./projects.js"
import { renderTerms } from "./terms.js"
import { countTo } from "./facts.js"

onReady(() => {
  selectArray("[data-terms]").forEach(renderTerms)
  selectArray("[data-topics]").forEach(renderProjects)
  selectArray("[data-locations]").forEach(renderLocations)
  selectArray("[data-projects]").forEach(renderProjectsTeaser)
})

/*
  selectArray(".marquee").forEach((el) => {
    el.addEventListener("click", () => {
      el.classList.add("is-disabled")
      document.cookie = "marquee=disabled;path=/;max-age=86400"
    })

    if (document.cookie.match(/marquee=disabled/)) {
      el.classList.add("is-disabled")
    }
  })
  */

// selectArray(".event").forEach(initEventBox)

onReady(() => {
  selectArray(".is-highlighted p").forEach((p) => {
    p.innerHTML = `<span>${p.innerHTML}</span>`
  })
})

const initMenuObserver = () => {
  const io = window.IntersectionObserver
  if (!io) return

  const callback = (entries) => {
    entries.forEach((entry) =>
      entry.target.classList.toggle("is-off", !entry.isIntersecting)
    )
  }

  new io(callback).observe(select(".top"))
}

const initSharingPopups = () => {
  selectArray(".sharing-button").forEach((el) =>
    el.addEventListener("click", (e) => {
      e.preventDefault()
      const win = window.open(
        e.target.href,
        "sharing",
        "height=600,width=800,resizable=yes,scrollbars=yes"
      )
      win.focus()
    })
  )
}

/*
const initEventBox = (el) => {
  // No polyfill, we just don't care
  const io = window.IntersectionObserver
  if (!io) return

  const callback = (entries) => {
    entries.forEach((entry) => {
      entry.target.classList.toggle("is-intersecting", entry.isIntersecting)
    })
  }

  new io(callback, {threshold: 1}).observe(el)
}
*/

onReady(() => {
  const io = window.IntersectionObserver
  if (!io) return

  const callback = (entries) => {
    entries.forEach((entry) => {
      const el = entry.target
      if (entry.isIntersecting && el.dataset.counted !== "1") {
        el.dataset.counted = "1"

        const target = parseInt(el.textContent.replace(/[^0-9]+/g, ""), 10)
        countTo(el, target, 2000)
      }
    })
  }

  const instance = new io(callback)
  selectArray(".fact__number").forEach((el) => instance.observe(el))
})

onReady(initMenuObserver)
onReady(initSharingPopups)

onReady(() => {
  const cb = select("#menu-open")

  select(".menu__menu")?.addEventListener?.("click", (e) => {
    if (e.target.tagName !== "A") return
    const href = e.target.getAttribute("href")
    if (!/^#/.test(href)) return
    let el
    try {
      el = select(href)
    } catch (exc) {
      /* nothing */
    }
    if (el) {
      e.preventDefault()
      scrollIntoView(el, {
        behavior: "smooth",
        block: "start",
        // scrollMode: "if-needed",
      })
      window.history.replaceState("", "", href)
      if (cb) {
        cb.checked = false
      }
    }
  })
})

onReady(() => {
  const mQuery = window.matchMedia("(min-width: 640px)")
  const svgs = selectArray(".facts-container--red > svg").map((el) => [
    el,
    el.getAttribute("viewBox"),
  ])
  // console.log(svgs)
  const setViewBox = () => {
    if (mQuery.matches) {
      svgs.forEach(([el, viewBox]) => el.setAttribute("viewBox", viewBox))
    } else {
      svgs.forEach(([el]) => el.setAttribute("viewBox", "0 0 520 310"))
    }
  }
  mQuery.addEventListener("change", setViewBox)
  setViewBox()
})
