import { Fragment, h, render } from "preact"
import { useState } from "preact/hooks"

import { Cards } from "./cards.js"
import { useInitialData } from "./utils.js"

const filterProjects = (projects, selected) =>
  selected
    ? projects.filter((project) => project.topicSlugs.includes(selected))
    : projects
const topicSlugs = (projects) =>
  projects.flatMap((project) => project.topicSlugs)
// const toggleSlug = (slugs, slug) =>
//   slugs.includes(slug) ? slugs.filter((s) => s !== slug) : [...slugs, slug]

const Topics = () => {
  const topics = useInitialData("initial-topics")
  const initialProjects = useInitialData("initial-projects")

  const [selected, setSelected] = useState("")

  const projects = filterProjects(initialProjects, selected)
  const allTopics = topicSlugs(initialProjects)

  // console.log({ topics, selected, alsoTopics, projects })

  return (
    <>
      <div class="topics">
        {topics.map((topic) => {
          if (!allTopics.includes(topic.slug)) return null

          const isSelected = topic.slug === selected

          return (
            <a
              key={topic.slug}
              href={topic.url}
              class={`topic ${isSelected ? "is-selected" : ""}`}
              onClick={(e) => {
                e.preventDefault()
                setSelected(isSelected ? "" : topic.slug)
              }}
            >
              {topic.name}
            </a>
          )
        })}
        {selected && (
          <button
            type="button"
            class="button is-clear"
            onClick={() => setSelected("")}
          >
            Clear
          </button>
        )}
      </div>
      <Cards projects={projects} />
    </>
  )
}

export function renderProjects(el) {
  el && render(<Topics />, el)
}

/*
const RelatedProjects = ({ selected }) => {
  const initialProjects = useInitialData("initial-projects")
  // const projects = filterProjects(initialProjects, selected)
  // return <Cards projects={projects} />
  // TODO only related projects (at least one topic matches)
  console.log({ selected })
  const projects = randomN(initialProjects, 3)
  return <Cards projects={projects} />
}

export function renderRelatedProjects(el) {
  render(<RelatedProjects selected={el.dataset.projectTopics.split(",")} />, el)
}
*/

export function renderProjectsTeaser(el) {
  let data
  try {
    data = JSON.parse(el.dataset.projects)
  } catch (exc) {
    window.console.error(exc)
    return
  }

  render(<Cards projects={data} />, el)
}
