import { h, render } from "preact"
import { useState } from "preact/hooks"

import { randomChoice, randomN, useInitialData } from "./utils.js"

const Terms = ({ klass }) => {
  const { terms, colors } = useInitialData("initial-terms")
  const [firstColor, secondColor] = randomN(colors, 2)

  const [term, setTerm] = useState(() => randomChoice(terms))

  return (
    <div class={`terms-wrapper ${klass || ""}`}>
      <a href={term.url} class="terms">
        <div class={`term first bg-${firstColor}`}>{term.first}</div>
        <div class={`term second bg-${secondColor}`}>{term.second}</div>
        <button
          class="button is-random"
          type="button"
          onClick={(e) => {
            e.preventDefault()
            setTerm(randomChoice(terms.filter((t) => t !== term)))
          }}
        >
          surprise me
        </button>
        <div class="banner">
          <span class="banner-terms">
            {term.first} and {term.second}.{" "}
          </span>
          <span class="banner-slogan">{term.slogan} </span>
          <u>Find out more</u>
        </div>
      </a>
    </div>
  )
}

export function renderTerms(el) {
  el && render(<Terms klass={el.dataset.terms} />, el)
}
