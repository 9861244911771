import { Fragment, h, render } from "preact"
import { useEffect, useState } from "preact/hooks"

import { Cards } from "./cards.js"
import { select, selectArray, useInitialData } from "./utils.js"

const initialContinent = () => document.body.dataset.continent || "europe"

const Locations = () => {
  const [continent, setContinent] = useState(initialContinent)
  const locations = useInitialData("locations")

  // const initialProjects = useInitialData("initial-projects")
  // const projects = filterProjects(initialProjects, selected)
  // const allTopics = topicSlugs(initialProjects)

  // console.log({ topics, selected, alsoTopics, projects })

  useEffect(() => {
    select("#world").addEventListener("click", (e) => {
      const c = e.target.closest("g.continent")
      c && setContinent(c.id)
    })
  }, [])

  useEffect(() => {
    selectArray("#world g.continent").forEach((c) => {
      c.classList.toggle("active", c.id === continent)
    })
  }, [continent])

  const activeLocations = locations.filter(
    (location) => location.continent === continent
  )
  const continentTitle = activeLocations.length
    ? activeLocations[0].continentTitle
    : ""

  return (
    <>
      <h2 className="continent-title">{continentTitle}</h2>
      <Cards projects={activeLocations} />
    </>
  )
}

export function renderLocations(el) {
  render(<Locations />, el)
}
